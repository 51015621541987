.filemanage #kt_aside, .filemanage #kt_footer, .filemanage #kt_header, .filemanage #kt_subheader {
    display: none !important;
}
.filemanage #kt_wrapper {
    margin: 0 !important;
    padding: 0 !important;
}
.filemanage {
    width: 80vw;
    height: auto;
    margin: 0 auto;
}