.profilePage {
    display: block;
}
.form-group.row {
    text-align: left !important;
}
.form-group.row  label {
    text-align: right;
}
.form-group.row>label.col-form-label {
    text-align: right;
    padding-top: 18px;
}
.MuiFormControl-root {
    width: 100%;
}
.makeStyles-textField-119 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.profileCountry label.MuiFormLabel-root, .profileTime label.MuiFormLabel-root {
    background: #fff;
    padding-right: 5px;
    padding-left: 5px;
    margin-left: -5px;
}
.MuiFormControl-root {
    margin: 0 !important;
    min-width: 120px;
}

.image-input .image-input-wrapper {
    max-width: 120px !important;
}