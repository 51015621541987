h3.dash-time-title, h3.dash-time-title2 {
    position: relative;
    margin-left: 80px;
    margin-top: 30px;
    z-index: 9;
    width: 100% !important;
    line-height: 1;
}

element.style {
}
h3.dash-time-title2 {
    margin-left: -15px;
}
h3.dash-time-title2 span {
    text-decoration: none !important;
    font-size: 14px;
    float: right;
    margin-right: 120px;
}
h3.dash-time-title2 {
    left:30px;
}
h3.dash-time-title2 span:hover {
    color: #333 !important;
    text-decoration: underline !important;
}
.vertical-timeline-element-content .vertical-timeline-element-date {
    display: none !important;
}
.dashCount .card {
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
}
.dashCount .card-body .count {
    font-size: 40px;
    font-weight: bold;
    line-height: 1;
}
.dashCount .card-body .dashDuration {
    text-align: right;
    font-size: 20px;
    float: right;
    font-weight: 700;
    color: #666;
}
.dashCount .card-body .dashDuration span {
    display: block;
    font-size: 25px;
    margin-top: -12px;
    color: #ddd;
}
.hour .dashDuration {
    color: #3699ff !important;
}
.hour .dashDuration span {
    color: #3699ff50 !important;
}
.day .dashDuration {
    color:#1bc5bd !important;
}
.day .dashDuration span {
    color: #1bc5bd50 !important;
}
.week .dashDuration {
    color: #8950FC !important;
}
.week .dashDuration span {
    color: #8950FC50 !important;
}
.dashCount .dashTitle {
    font-weight: 700;
    color: #6993ff;
    font-size: 16px;
}
.dashCount .day .dashTitle {
    color: #1bc5bd;
}
.dashCount .week .dashTitle {
    color: #8950fc;
}
.dashCount .hour {
    border-color:#e1e9ff;
}
.dashCount .day {
    border-color: #c9f7f5;
}
.dashCount .week {
    border-color:#eee5ff;
}
img.dashboard_logo_icon {
    border-radius: 60px;
}
.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    color: #3F4254 !important;
}
span.dashboardBullet {
    width: 100% !important;
    height: 100% !important;
    background: #2196f3 !important;
    border: 0px solid !important;
    z-index: 999999;
    position: absolute;
    border-radius: 50%;
}
.vertical-timeline .userBlk img.dashUserImg {
    min-width: 60px !important;
    min-height: 60px !important;
    width: 60px !important;
    height: 60px !important;
    border-radius: 60px;
    border: 3px solid #ffffff;
    box-shadow: 0 0 5px #b5b3b3;
}
.modal-body .userBlk .userImg img.dashUserImg {
    min-width: 70px !important;
    min-height: 70px !important;
    width: 70px !important;
    height: 70px !important;
    border-radius: 60px;
    border: 2px solid #ffffff;
    box-shadow: 0 0 5px #b5b3b3;
}
.vertical-timeline .userBlk .userImg, .modal-body .userBlk .userImg {
    display: inline-block;
    margin-right: 10px;
}
.vertical-timeline .userBlk .userName {
    font-size: 20px;
    font-weight: 500;
}
.vertical-timeline-element-content .userBlk, .modal-body .userBlk {
    width: 100%;
    text-align: left;
    display: flex;
    padding-right: 10px;
}
.vertical-timeline-element .userPost {
    display: block;
    margin-top: 15px;
}
.userPost .userPostMsg {
    margin-bottom: 2px;
}
.vertical-timeline .userBlk span.msgBlk {
    padding-top: 13px;
    width: auto;
    display: block;
    font-size: 12px;
}
.modal-body .userBlk span.msgBlk {
    padding-top: 15px;
    width: auto;
    display: block;
    font-size: 12px;
}
.vertical-timeline .userBlk span.msgBlk .user-name{
    font-weight: 600;
}
.modal-body .userBlk span.msgBlk .user-name {
    font-weight: 600;
    font-size: 16px;
}
.vertical-timeline .userBlk span.msgBlk .scanPlace, .modal-body .userBlk span.msgBlk .scanPlace {
    font-weight: 600;
    text-decoration: underline;
}
.vertical-timeline .userBlk span.msgBlk .scanTime {
    display: block;
    color: #888;
}
.modal-body .userBlk {
    margin-bottom: 20px;
}
.vertical-timeline-element-content {
    box-shadow: 0 4px 0 #c0dfff !important;
}
.vertical-timeline .vertical-timeline-element:nth-of-type(even) .vertical-timeline-element-content {
    box-shadow: 0 4px 0 #b7edea !important;
}
.vertical-timeline-element--no-children {
    box-shadow: 0 0px 0 transparent !important;
}
.vertical-timeline-element--no-children .vertical-timeline-element-content {
    box-shadow: 0 0 0 0 transparent !important;
}
.dashCount .card-body {
    z-index: 10;
}
span.dashboardBullet i {
    text-align: center;
    width: 30px;
    line-height: 29px;
    color: #fff;
    font-size: 15px;
    margin-left: 0px;
}
.dashCount .card-body.row {
    padding: 0;
}
.dashCount .card-body.row .col-md-6:first-child {
    padding-top: 32px;
    text-align: center;
}
.card-rounded-bottom .apexcharts-canvas svg {
    border-bottom-left-radius: 0 !important;
    //border-bottom-right-radius: 0 !important;
}
.dashCount .card i.bg-icon {
    font-family: "Font Awesome 5 Free";
    content: "\f007";
    color: #c9f7f550;
    font-size: 100px;
    position: absolute;
    top: 10px;
    left: 15px;
    line-height: 1;
    z-index: 1;
}
.dashCount .engages i.bg-icon {
    color: #e1e9ff50;
}
.dashCount .mensions i.bg-icon {
    color: #e4dbf650;
}
.dash-filter .MuiSelect-outlined {
    padding: 15px 30px 15px 15px;
}
.dash-filter label.MuiInputLabel-outlined {
    margin-top: -4px !important;
    opacity: 1;
}
.dash-filter label.MuiFormLabel-filled {
    margin-top: 2px !important;
    opacity: 0;
}
.dashCount .card {
    border: 1px solid #ccc;
}
.vertical-timeline-element:nth-of-type(even) span.dashboardBullet {
    background: #1bc5bd !important;
}
.vertical-timeline-element:last-child i.fa {
    width: 30px;
    text-align: center;
    height: 30px;
    vertical-align: 30px;
    background: #8950fc;
    color: #fff;
    border-radius: 30px;
    padding-top: 7px;
}
.vertical-timeline-element-icon {
    width: 30px !important;
    height: 30px !important;
}
.vertical-timeline::before {
    height: 101%;
}
.dash-filter .MuiFormControl-root {
    width: 100%;
    background: #fff;
    overflow: hidden;
}
.filter-status {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 45px;
}
.dashCount .filter-status, .dashCount .dash-filter {
    margin-bottom: 15px;
}
.topbar .d-flex .symbol-light-success {
    min-width: 10px !important;
}
.symbol.symbol-35 .symbol-label {
    width: 35px !important;
    height: 35px;
    max-width: 35px !important;
    min-width: 35px !important;
}
.dash-filter label.MuiInputLabel-outlined {
    font-weight: 500;
}
.vertical-timeline-element .action-menu {
    position: absolute;
    right: 0;
}
.MuiPopover-root .MuiPaper-elevation1 {
    box-shadow: 0 0 2px 0 rgba(82, 63, 105, 0.1) !important;
}
button.MuiFab-secondary {
    min-width: 10px !important;
}
.dashPhtoBlk {
    position: relative;
    overflow: hidden;
}
.dashPhtoBlk button.btn-mail {
    position: absolute;
    border-radius: 0 !important;
    padding: 10px 20px 2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    top: -4px;
    right: -47px;
    width: 120px;
}
.vertical-timeline-element:nth-of-type(even) .dashPhtoBlk button.btn-mail {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    right: auto;
    left: -47px;
}
button.btn-mail SVG g path {
    fill: #fff;
}
.emailBlk .emailContent {
    display: block;
    margin-bottom: 25px;
}
.emailBlk .emailContent span.userName {
    font-weight: 600;
}
span.userLoc {
    margin-right: 4px;
    vertical-align: middle;
}
span.userLoc i.fa {
    color: #c9c9c9;
}
.modal-content .modal-body .comapnyName {
    font-size: 16px;
    font-weight: 600;
    padding-right: 60px;
}
.modal-content .modal-body .companyAddr {
    font-size: 13px;
    font-style: italic;
    text-decoration: underline;
    padding-right: 60px;
}
.emailBlk .sendBtn {
    float: right;
    margin-top: -45px;
}
.emailBlk .sendBtn button {
    min-width: 10px !important;
    margin: 0;
    width: 50px;
    height: 50px;
    border-radius: 50px;
}
.emailBlk .sendBtn .material-icons {
    line-height: 1.15;
    margin-left: 4px;
}
.dashPhtoBlk img {
    width: 100%;
    height: auto;
}
.filter-status .fltStatus {
    border: 1px solid #c4c4c4;
    background: #fff;
    border-radius: 4px;
    padding: 0 15px;
}
.vertical-timeline--animate .vertical-timeline-element--no-children .vertical-timeline-element-content {
    box-shadow: 0px 0px 0px 0px transparent !important;
}
.vertical-timeline.vertical-timeline--one-column-right::before {
    right: 18px;
    left: unset;
}
.vertical-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: white;
}
.vertical-timeline--one-column-right .vertical-timeline-element-icon {
    right: 0;
    left: unset;
}
.vertical-timeline--one-column-right .vertical-timeline-element-content {
    margin-right: 60px;
    margin-left: unset;
}
.vertical-timeline--one-column-right .vertical-timeline-element-content-arrow {
    content: '';
    position: absolute;
    top: 16px;
    right: unset;
    left: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-left: 7px solid white;
}
.vertical-timeline--one-column-left .vertical-timeline-element-icon {
    top: 7px;
    left: 5px;
    right: auto;
}
.vertical-timeline--one-column-right .vertical-timeline-element-icon {
    top: 7px;
    right: 6px;
    left:auto;
}
.no-shadow .vertical-timeline-element-content {
    box-shadow: 0 0px 0 #b7edea !important;
}
.vertical-timeline .vertical-timeline-element:nth-of-type(even):last-child .vertical-timeline-element-content,
.vertical-timeline .vertical-timeline-element:nth-of-type(odd):last-child .vertical-timeline-element-content {
    box-shadow: 0 0px 0 #b7edea !important;
}
.vertical-timeline {
    height: 100%;
}
.emptyBlk {
    font-weight: 600;
}
.empty-el-row .vertical-timeline-element-date {
    display: none !important;
}
.mrchntPostBlk {
    margin-left: 60px;
    background: #fff !important;
    border-radius: 50px;
    padding: 10px 12px;
    box-shadow: 0 4px 0 #ccc !important;
    clear: both;
    display: flex;
    position: relative;
    max-width: 540px;
    margin-top: 36px;
    margin-right: 0;
}
.mrchntPostBlk .merchntImg {
    position: relative;
    float: left;
}
.mrchntPostBlk .merchntImg img {
    max-width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    border: 0px solid #ffffff;
    box-shadow: 0 0 5px #b5b3b3;
}
.mrchntPostBlk .openPostPopup {
    line-height: 40px;
    font-size: 16px;
    background:#f0f2f5;
    width: 100%;
    border-radius: 40px;
    padding-left: 13px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
}
.mrchntPostBlk .openPostPopup:hover {
    background:#eee;
}
p.dashBPopupP {
    text-align: left !important;
    padding: 0 !important;
}
.postDD {
    width: 50%;
    padding: 20px 10px 10px;
    display: inline-block;
}
.postDD .MuiSelect-outlined {
    padding-top: 14px;
    padding-bottom: 14px;
}
.postDD .MuiInputLabel-outlined {
    margin: 0 !important;
    margin-top: -5px !important;
    background: #fff;
    padding-left: 5px;
    padding-right: 5px;
}
.postDD .MuiInputLabel-outlined.MuiFormLabel-filled {
    margin-top: 2px !important;
}
.postTextarea {
    padding: 0px;
}
.postDD.hide {
    display: none !important;
}
.postDD.show {
    display: inline-block !important;
}
#postBlkBody {
    padding: 10px;
}
#postBlkBody .headerBlk {
    display: block;
    text-align: center;
    height: 45px;
    border-bottom: 1px solid #ddd;
}
#postBlkBody .headerBlk h3 {
    margin: 0;
    line-height: 35px;
    font-size: 20px;
}
#postBlkBody .addPostForm {
    display: block;
    padding: 20px 5px 10px;
    position: relative;
    overflow: hidden;
}
#postBlkBody .addPostForm .bSelectBlk {
    display: flex;
    margin-bottom: 10px;
    height: 50px;
}
#postBlkBody .addPostForm .bSelectBlk .bAvatar {
    margin-right: 11px;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    overflow: hidden;
}
#postBlkBody .addPostForm .bSelectBlk .bAvatar img {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    object-fit: cover;
    border-radius: 40px;
}
#postBlkBody .addPostForm .bSelectBlk .bData {
    width: 100%;
}
#postBlkBody .addPostForm .bSelectBlk .bData .bTitleData {
    display: flex;
}
#postBlkBody .addPostForm .bSelectBlk .bData .bTitleData .bTitle {
    font-size: 15px;
    font-weight: 600;
    margin-right: 10px;
    vertical-align: middle;
    line-height: 1;
}
#postBlkBody .addPostForm .bSelectBlk .bData .bTitleData .editTitle i {
    vertical-align: baseline;
    font-size:14px;
}
#postBlkBody i.fa.fa-times {
    width: 36px;
    height: 36px;
    padding: 0;
    text-align: center;
    line-height: 36px;
    font-size: 20px;
    background: #f0f2f5;
    border-radius: 36px;
    top: 9px;
    right: 9px;
}
#postBlkBody i.fa.fa-times:hover {
    background: #e4e6eb !important;
    color: #333 !important;
}
.selectCommunity {
    cursor: pointer;
}
.selectCommunity i.fa {
    color: #333;
    font-size: 13px;
    vertical-align: middle;
}
.selectCommunity .cum_name {
    font-size: 11px;
    vertical-align: middle;
    margin: 0 5px;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
}
.selectCommunity:hover {
    background-color: #dee0ea;
}
.postTextarea .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
    padding: 0;
    border: 0px none transparent !important;
}
.postTextarea .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0)!important;
}
.postTextarea .MuiOutlinedInput-inputMultiline {
    padding: 0;
    min-height: 100px;
    font-size: 19px;
}
.postTextarea .MuiInputLabel-outlined, .postTextarea .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(2px, 4px) scale(1);
    font-size: 19px;
    color:#999;
    margin: 0;
}
.postTextarea .MuiInputLabel-outlined.MuiInputLabel-shrink {
    color:#f9f9f9;
    z-index: 0;
}
.postTextarea .MuiOutlinedInput-multiline {
    padding: 0;
    font-size: 16px;
    line-height: 1.2;
}
.btn-post {
    text-align: center;
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background: #1877f2;
    line-height: 38px;
    border-radius: 6px;
    margin-top: 15px;
    cursor: pointer;
}
.btn-post:focus, .btn-post:hover {
    background: #156ad9;
    outline: 0;
}
.btn-post.disabled {
    pointer-events: none;
    background: #ddd;
    color: #b4b4b4;
}
#sImgBlk {
    box-shadow: 0 1px 2px #0000001a;
    border: 1px solid #ddd;
    border-radius: 7px;
    padding: 12px;
    margin-top: 15px;
}
#sImgBlk .avatarLabel {
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    color: #666;
    width: 50%;
    line-height: 1.2;
}
#sImgBlk input[type="file"] {
    display: none;
}
#sImgBlk label.text-link i.fa.fa-image {
    font-size: 30px;
    margin-top: -4px;
    margin-right: 1px;
    color: #009688;
}
#sImgBlk label.text-link {
    margin: 0;
    float: right;
    display: inline-block;
    width: 50%;
    text-align: right;
}
#sImgBlk label.text-link:hover i {
    color: #087d72;
}
#sImgBlk label.text-link i.fa.fa-image:hover {
    color: #087d72;
}
.blockUI {
    z-index: 10500 !important;
}
.modal-backdrop.show {
    opacity: 0.4;
}




@media only screen and (min-width: 1170px)
{
    .vertical-timeline {
        padding: 0 !important;
        margin-top: -15px !important;
    }
    .vertical-timeline--two-columns .vertical-timeline-element-icon {
        width: 30px !important;
        height: 30px !important;
        left: 50% !important;
        margin-left: -15px !important;
        margin-top: 15px !important;
        font-size: 0 !important;
        box-shadow: 0 0 0 5px #ffffff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
        background: #2196f3 !important;
    }
    .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
        top: 12px !important;
    }
    .vertical-timeline-element {
        margin:3em 0 !important;
    }
}
@media only screen and (max-width: 1444px)
{
    .dashCount .card-body .count {
        font-size: 40px;
    } 
    .dashCount .card-body .dashDuration {
        font-size: 15px;
    }
    .dashCount .card-body .dashDuration span {
        font-size: 20px;
        margin-top: -8px;
    }
    
}
@media (max-width: 1260px) and (min-width:992px) 
{
    .dashCount .card-body .count {
        font-size: 34px;
        text-align: center;
    }
    .dashCount .card-body .dashDuration {
        font-size: 14px;
        text-align: center;
        display: block;
        width: 100%;
        margin-bottom: 10px;
    }
    .dashCount .card-body .dashDuration span {
        font-size: 18px;
        margin-top: -6px;
    }
    .dashCount .dashTitle {
        display: block;
        text-align: center;
    }
    .filter-status {
        font-size: 1.6rem;
    }
    h3.dash-time-title, h3.dash-time-title2 {
        font-size: 16px;
    }
    h3.dash-time-title2 {
    }
    .mrchntPostBlk .openPostPopup {
        font-size: 12px;
    }
}
@media (max-width: 991px) and (min-width:481px) 
{
    .dashCount .card-body .count {
        font-size: 26px;
    }
    .dashCount .card-body .dashDuration {
        font-size: 12px;
    }
    .dashCount .card-body .dashDuration span {
        font-size: 15px;
        margin-top: -6px;
    }
    .filter-status {
        font-size: 1.6rem;
    }
    h3.dash-time-title, h3.dash-time-title2 {
        top: 0;
    }
    h3.dash-time-title2 {
        left:30px;
    }
    .col-md-6.leftBlk {
        display: contents;
    }
    .mrchntPostBlk .openPostPopup {
        font-size: 13px;
    }
    .mrchntPostBlk {
        max-width: 900px;
        width: 100%;
    }
    
}
@media (max-width: 480px)
{
    .dashCount .card-body .count {
        font-size: 36px;
        text-align: center;
    }
    .dashCount .card-body .dashDuration {
        font-size: 16px;
        display: block;
        text-align: center;
        width: 100%;
    }
    .dashCount .card-body .dashDuration span {
        font-size: 20px;
        margin-top: -9px;
    }
    .dashCount .dashTitle {
        display: block;
        text-align: center;
        float: none;
    }
    h3.dash-time-title, h3.dash-time-title2 {
        top: 0;
    }
    h3.dash-time-title2 {
        left:30px;
    }
    .mrchntPostBlk {
        width: calc(100% - 24px);
        margin: 15px 12px;
    }
}
@media (max-width:1169px) {
    .vertical-timeline-element-icon {
        width: 30px !important;
        height: 30px !important;
        margin-left: 5px !important;
        margin-top: 8px !important;
    }
}
@media(max-width:767px){
    .filter-status {
        font-size: 1.5rem;
        text-align: center;
    }
}
@media (max-width: 1169px)
{
    .vertical-timeline-element .dashPhtoBlk button.btn-mail {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        right: auto;
        left: -47px;
    }
}

.selectCommunity {
    min-width: 118px;
    height: 25px;
}
.selectCommunity .spinner1 {
    display: none;
}
.selectCommunity.spinners .spinner1 {
    display: flex;
    align-items: center;
}
.selectCommunity.spinners i.fa, .selectCommunity.spinners .cum_name {
    display: none;
}
.spinner1 {
    display: block;
    position: relative;
    height: 15px;
    width: 15px;
    margin: 0 auto;
    border: 2px solid transparent;
    border-top-color: #000000;
    border-bottom-color: #000000;
    border-radius: 50%;
    -webkit-animation: spinner1 1s linear infinite;
    animation: spinner1 1s linear infinite;
}
.spinner1:after {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    border: 2px solid transparent;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.45);
}

@keyframes spinner1 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spinner1 {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }







.spinner2,
.spinner2:before,
.spinner2:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.spinner2 {
  color: #ffffff;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.spinner2:before,
.spinner2:after {
  content: '';
  position: absolute;
  top: 0;
}
.spinner2:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner2:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}



.selectCommunity #dots4 {
    display: none;
}
.selectCommunity.spinners #dots4 {
    display: flex;
    align-items: center;
}

#dots4 {
    position: relative;
    height: 12px;
    width: 55px;
    margin: 1px auto 0;
}

#dots4 span {
  position: absolute;
  width: 8px;
  height: 8px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  background: #888;
  -webkit-animation: dots4 1.2s infinite ease-in-out both;
          animation: dots4 1.2s infinite ease-in-out both;
  margin-right: 5px;
}

#dots4 span:nth-child(1) {
  left: 0px;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

#dots4 span:nth-child(2) {
  left: 15px;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

#dots4 span:nth-child(3) {
  left: 30px;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

#dots4 span:nth-child(4) {
  left: 45px;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

@keyframes dots4 {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes dots4 {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

.businessBlk {
    position: absolute;
    top: 0;
    left: 100%;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: #fff;
    z-index: 10;
    width: 100%;
    transition: all 0.5s;
}
.businessBlk .businessList {
    height: 100%;
    overflow-y: overlay;
}
.businessBlk .businessList .bSelectBlk {
    border-bottom: 1px solid #eee;
    margin: 0 !important;
    height: 60px !important;
    padding: 10px;
    cursor: pointer;
}
.businessBlk .businessList .bSelectBlk:hover {
    background: #f4f4f4;
}
.businessBlk .businessList .bSelectBlk:last-child {
    border: 0;
}
.businessBlk .businessList .bSelectBlk .bData {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 40px;
}
.addPostForm .businessBlk {
    left: 100%;
}
.addPostForm.bslide .businessBlk {
    left: 0;
}


.mrchntPostBlk {
    display: none;
}